import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title=""
        description=""
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="mb-20 pt-10 md:mb-32 md:pt-16">
        <div className="container">
          <header className="mb-10 text-center md:mb-14">
            <h1>FHA Loan Calculator</h1>
          </header>

          <iframe
            src="https://mortgage-tools.flywheelsites.com/fha-loan-calculator/"
            width="100%"
            height="100%"
            title="Mortgage Calculator"
            frameBorder="0"
            className="h-[1200px] md:h-[700px]"
          ></iframe>
        </div>
      </section>
    <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Mortgage Calculators.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Mortgage Calculators.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
